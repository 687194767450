import { Injectable } from '@angular/core';
import { HttpserviceService } from './httpservice.service';

@Injectable({
  providedIn: 'root'
})
export class StoresService {

  constructor(private httpserviceService: HttpserviceService) { }

  filterStores(params: any = ""){
    return this.httpserviceService.get("stores?filters=" + params);
  }

  searchStores(page: any, params: any){
    return this.httpserviceService.get("search/stores?page=" + page +"&"+ params);
  }

  searchStoresAutocomplete(params: any, region_id: any){
    return this.httpserviceService.get("search/autocomplete/" + params + "?region=" + region_id);
  }

  getStoreById(store_id: any){
    return this.httpserviceService.get("store/" + store_id);
  }

  updateStoreById(data: any, store_id: number){
    return this.httpserviceService.post(data, "store/" + store_id);
  }

  updateDataById(data: any, store_id: number){
    return this.httpserviceService.post(data, "store/" + store_id +"/update_data/?");
  }

  getStoresMoney(params: any){
    return this.httpserviceService.get("stats/stores_money" + params);
  }
}
